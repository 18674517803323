import './App.css';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import token from './abis/token.json'
import farmland from './abis/farmland.json'
import moment from 'moment/moment';
import Loading from './components/Loading';

function App() {
  var [account, setAccount] = useState("Connect Wallet")
  var [amount, setAmount] = useState("")
  var [stakeText, setstakeText] = useState("Stake $MSHARE")
  var [claimText, setclaimText] = useState("Claim Rewards")
  var [unstakeText, setunstakeText] = useState("Unstake $MSHARE")
  var [unstakeAllText, setunstakeAllText] = useState("Unstake All")
  //righthand variables
  var [userBalance, setuserBalance] = useState(0)
  var [userStaked, setuserStaked] = useState(0)
  var [userWeight, setuserWeight] = useState(0)
  //lefthand variables
  var [endtime, setendtime] = useState(0)
  var [epoch, setepoch] = useState(null)
  var [epochRewards, setepochRewards] = useState(<Loading/>)
  var [totalStaked, settotalStaked] = useState(<Loading/>)
  //contract addresses
  const tokenAddress = "0xC8Ca9026Ad0882133Ef126824F6852567c571A4E";
  const farmlandAddress = "0x7551Fb39ce830282Abea40E44B791641A4ec0B92";
  //RPC provider
  const rpcProvider = "https://rpc.ankr.com/fantom";
  //countdown params
  const countdownText = "Claim Period Has Started"
  const startTime = 1670182200
  const [countdown, setCountdown] = useState("- Days --:--:--")
  //useffect for countdown and getting info
  useEffect(()=>{
    getInfo()
    setInterval(()=>getInfo(),10000)
    setInterval(()=>{
      const timeRemaining = startTime-(Date.now()/1000).toFixed(0)
      const duration = moment.duration(timeRemaining,"seconds")
      setCountdown(`${duration.days()} Days ${duration.hours()} Hours ${duration.minutes()} Minutes ${duration.seconds()} Seconds`)
    }, 1000)
  },[])
  //functions
  //error Handler
  async function errorHandler(ex){
    if(ex.error === undefined){
      alert(ex.message)
    }else{
      alert(ex.error.data.message)
    }
  }
  //connect function
  async function connect(){
    if(typeof window.ethereum !== 'undefined'){
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const chainId = await provider.getNetwork()
      if(chainId.chainId !== 250){
        alert("Please connect to Fantom Opera network!")
        window.location.reload()
      }else{
        try{
          const accounts = await provider.send("eth_requestAccounts", [])
          setAccount(accounts[0].slice(0,7)+"...")
          window.ethereum.on("accountsChanged", () => window.location.reload())
          window.ethereum.on("chainChanged", () => window.location.reload())
          getUserInfo()
        }catch(ex){
          errorHandler(ex)
        }
      }  
    }else{
      alert("Please install MetaMask or use MetaMask browser!")
    }
  }
  //stake function
  async function stake(){
    if(amount <= 0){
      alert("Please enter a valid amount to Stake your $MSHARE!")
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const allowance = await checkAllowance()
    if(allowance < amount){
      await approve()
    }
    try{
      setstakeText("Staking...")
      const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
      const stakeAmount = ethers.utils.parseEther(amount.toString())
      const stake = await farmlandContract.stake(stakeAmount)
      setstakeText("Please Wait")
      await stake.wait()
      alert(`You have successfully staked ${amount} $MSHARE!`)
    }catch(ex){
      errorHandler(ex)
    }finally{
      getUserInfo()
      setstakeText("Stake $MSHARE")
    }
  }
  //claim function
  async function claim(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
    try{
      setclaimText("Claiming Rewards")
      const claim = await farmlandContract.claimRewards()
      setclaimText("Please Wait")
      await claim.wait()
      alert("You have successfully claimed your rewards!")
    }catch(ex){
      errorHandler(ex)
    }finally{
      setclaimText("Claim Rewards")
    }
  }
  //unstake function
  async function unstake(){
    if(userStaked <= 0){
      alert("You can't unstake if you have no $MSHARE staked!")
      return;
    }
    if(amount <= 0){
      alert("Please enter a valid amount to Unstake your $MSHARE!")
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
    try{
      setunstakeText("Unstaking...")
      const unstakeAmount = ethers.utils.parseEther(amount.toString())
      const unstake = await farmlandContract.unstake(unstakeAmount)
      setunstakeText("Please Wait")
      await unstake.wait()
    }catch(ex){
      errorHandler(ex)
    }finally{
      getUserInfo()
      setunstakeText("Unstake $MSHARE")
    }
  }
  //unstake all function
  async function unstakeAll(){
    if(userStaked <= 0){
      alert("You can't unstake all if you have no $MSHARE staked!")
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
    try{
      setunstakeAllText("Unstaking All...")
      const unstake = await farmlandContract.unstakeAll()
      setunstakeAllText("Please Wait")
      await unstake.wait()
    }catch(ex){
      errorHandler(ex)
    }finally{
      getUserInfo()
      setunstakeAllText("Unstake All $MSHARE")
    }
  }
  //approve function
  async function approve(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const tokenContract = new ethers.Contract(tokenAddress,token.abi,provider.getSigner())
    const toApprove = ethers.utils.parseEther(amount.toString())
    try{
      setstakeText("Approving...")
      const approve = await tokenContract.approve(farmlandAddress, toApprove)
      setstakeText("Please Wait")
      await approve.wait()
    }catch(ex){
      errorHandler(ex)
    }finally{
      setstakeText("Stake $MSHARE")
    }
  }
  //check allowance function
  async function checkAllowance(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send("eth_requestAccounts", [])
    const tokenContract = new ethers.Contract(tokenAddress,token.abi,provider.getSigner())
    const allowanceBigNumber = await tokenContract.allowance(accounts[0], farmlandAddress)
    const allowance = ethers.utils.formatUnits(allowanceBigNumber,18)
    return allowance
  }
  //general info function
  async function getInfo(){
    const provider = new ethers.providers.JsonRpcProvider(rpcProvider)
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider)
    const endTimestampBigNumber = await farmlandContract.endTimestamp()
    const endTimestamp = ethers.utils.formatUnits(endTimestampBigNumber,0)
    const getEpochBigNumber = await farmlandContract.epoch()
    const getEpoch = Number(ethers.utils.formatUnits(getEpochBigNumber,0))
    const totalStakedBigNumber = await farmlandContract.totalStaked()
    setepochRewards(100)
    settotalStaked(Number(ethers.utils.formatUnits(totalStakedBigNumber,18)).toFixed(4))
    setendtime(endTimestamp)
    setepoch(getEpoch)
  }
  //user info function
  async function getUserInfo(){
    setuserBalance(<Loading/>)
    setuserStaked(<Loading/>)
    setuserWeight(<Loading/>)
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const accounts = await provider.send("eth_requestAccounts",[])
    const tokenContract = new ethers.Contract(tokenAddress,token.abi,provider.getSigner())
    const farmlandContract = new ethers.Contract(farmlandAddress,farmland.abi,provider.getSigner())
    const balanceBigNumber = await tokenContract.balanceOf(accounts[0])
    const balance = Number(ethers.utils.formatUnits(balanceBigNumber,18)).toFixed(4)
    const stakedBigNumber = await farmlandContract.userStakedAmount(accounts[0])
    const staked = Number(ethers.utils.formatUnits(stakedBigNumber,18)).toFixed(4)
    const weightBigNumber = await farmlandContract.stakerWeight(accounts[0])
    const weight = Number(ethers.utils.formatUnits(weightBigNumber,18)*100).toFixed(4)
    setuserBalance(balance)
    setuserStaked(staked)
    setuserWeight(weight)
  }
  return (
    <div className="App">
      <div id='Topbar'>
        <a href='https://magik.finance'>
          <h1>Magik Finance</h1>
        </a>
        <button onClick={connect}>{account}</button>
      </div>
      <h1>Magik Farmland</h1>
      <h3>Claim Period Has Started</h3>
      {/* <h4>Next Epoch: {countdown}</h4> */}
      <div id='Container'>
        <div id='Lefthand'>
        <h2>Magik Farmland</h2>
        <h3>Stake $MSHARE and Earn $FTM</h3>
        <span>Total staked: {totalStaked} $MSHARE</span>
        <span>Epoch Endtime: {endtime === 0 ? <Loading/> : moment.unix(endtime).format("LLL")}</span>
        <span>Epoch Rewards: ${epochRewards} USD worth of FTM</span>
        <span>Epoch: {epoch === null?<Loading/>:epoch+1}</span>
        <button onClick={() => window.open("https://magikswap.dog/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xC8Ca9026Ad0882133Ef126824F6852567c571A4E")}>Buy $MSHARE</button>
        </div>
        {
          account === "Connect Wallet" ?
          <div id='Farmland'>
            <span>Please Connect Your Wallet</span>
          </div>
          :
          <div id='Farmland'>
          <div id='Middle'>
        <input type="number" value={amount} onChange={e => setAmount(e.target.value)} placeholder="Enter Amount..."></input>
        <button onClick={stake}>{stakeText}</button>
        <button onClick={claim}>{claimText}</button>
        <button onClick={unstake}>{unstakeText}</button>
        <button onClick={unstakeAll}>{unstakeAllText}</button>
          </div>
          <div id='Righthand'>
        <span>Your balance:<br/> {userBalance} $MSHARE</span>
        <span>You staked:<br/> {userStaked} $MSHARE</span>
        <span>Your Weight:<br/> {userWeight}%</span>
        <span>Your Rewards:<br/> ${(Number(epochRewards*userWeight)/100).toFixed(4)} USD worth of FTM</span>
          </div>
          </div>
        }
        
      </div>
      <div style={{display:"grid",gap:"10px",margin:"0px 20px 30px 20px"}}>
      <h1>How Does It Work?</h1>
      <span>You stake your $MSHARE into the Farmland.</span>
      <span>After staking, you will be able to see your weight.</span>
      <span>Epoch Endtime is printed according to your local time.</span>
      <span>At the end of the Epoch, you can claim your rewards depending on your weight.</span>
      <span>You can not stake or unstake when the current epoch is ended. You have to wait for the next epoch.</span>
      <span>You have 48 hours to claim your FTM rewards, otherwise the rewards are sent back to the treasury.</span>
      <br/>
      <span>Deposit & Withdrawal Fees: 2%</span>
      <span>When a user withdraws their $MSHARE, half of the Withdrawal fee will be split between the stakers.</span>
      </div>
    </div>
  );
}

export default App;